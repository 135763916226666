@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Prompt&display=swap");

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #999;
}

/* Scrollbar Styling */

html {
  scroll-behavior: smooth;
}

body {
  font-family: "DB Heavent";
  font-size: 16px !important;
  scroll-behavior: smooth !important;
  padding: 0;
  margin: 0;
  background: url(../images/bg.webp) repeat;
}

a {
  text-decoration: none !important;
}

.t-green {
  color: #99cb4f;
}

.t-blue {
  color: #95edf9;
}

.t-darkblue {
  color: #0a85c7;
}

.t-white {
  color: #fff;
}

.t-black {
  color: #000;
}

.t-yellow {
  color: #eda411;
}

.t-orange {
  color: #f68b1f;
}

.t-pink {
  color: #f03e58;
}

.fw-bolder {
  font-weight: bolder !important;
  font-family: "DBHeaventBl" !important;
}

.fw-bold {
  font-weight: bold !important;
  font-family: "DBHeaventBl";
}

/* img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
} */

.header {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 9999;
}

.menu ul {
  text-align: center;
  margin: 0;
  padding: 0;
}
.menu ul li {
  display: inline-block;
  margin: auto 35px;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}
.menu ul li:hover .submenu {
  height: auto;
  opacity: 1;
  overflow: unset;
}
.menu ul li:hover a {
  color: #000;
}
.menu ul li:hover a::before {
  visibility: visible;
  transform: scale(1, 1);
}

.menu ul li.non-ac:hover a::before {
  visibility: visible;
  transform: scale(0, 0);
}

.menu ul li .submenu {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: -50%;
  width: 200px;
  background-color: #000;
  height: 0;
  line-height: 15px;
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  transition-delay: 0.1s;
  border-radius: 30px;
  margin-top: 0px;
  z-index: 1;
  right: -20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu ul li .submenu::before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #000;
  content: "";
  left: 50%;
  margin-left: 30px;
  position: absolute;
  top: 0;
  bottom: auto;
  z-index: 2;
  margin-top: -10px;
}

.menu ul li .submenu li {
  display: block;
  margin: 8px 0;
}

.menu ul li .submenu li:first-child {
  margin-top: 20px;
}

.menu ul li .submenu li:last-child {
  margin-bottom: 20px;
}

.menu ul li .submenu a {
  color: #fff;
  margin-top: 0px;
  font-size: 18px;
  display: inline-block;
  padding: 0;
}
.menu ul li .submenu a:hover {
  color: #fff;
  text-decoration: underline;
}

.menu ul li a {
  font-family: "DB Heavent";
  font-size: 24px;
  color: #000;
  display: block;
  padding: 0 7px;
  margin: 0;
  text-decoration: none;
  position: relative;
}

.menu ul li.non-ac a {
  padding: 7px;
}

.menu ul li.non-ac .submenu a {
  padding: 0;
}

.menu ul li a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -5px;
  left: 0px;
  background-color: #000;
  transition: all 0.2s ease-in-out;
  transform: scale(0, 0);
  visibility: hidden;
}

.menu ul li .submenu li:hover a::before {
  visibility: visible;
  transform: scale(1, 1);
}

.menu ul li .submenu a::before {
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  transform: scale(0, 0);
  visibility: hidden;
}

.menu-5 {
  border: 1px solid #000;
  border-radius: 30px;
  padding: 0 15px;
}

.menu ul .menu-5:hover {
  background-color: #000;
}

.menu ul .menu-5:hover a {
  color: #fff;
}

.menu ul .menu-5:hover a::before {
  visibility: hidden;
}

.drawer {
  z-index: 9999 !important;
  color: #fff;
}

.MuiDrawer-modal .MuiDrawer-paper {
  background-color: #000;
}

#nested-list-subheader {
  background-color: #000;
  color: #fff;
  border-bottom: 1px solid #444;
}

.MuiListItemButton-root {
  border-bottom: 1px solid #444 !important;
}

.menu-head,
.MuiListItemButton-root a {
  color: #fff;
}

.submenu {
  background-color: #fff;
  margin: 0;
  padding: 10px 10px;
  list-style: none;
}

.nav-mo li {
  border-bottom: 1px solid #ededed;
  margin: 0px 10px;
  padding: 5px 0;
}

.nav-mo li:last-child {
  border-bottom: none;
}

.nav-mo li a {
  color: #000;
  font-size: 20px;
}

section.carousel {
  height: 100dvh;
}

.itp_carousel {
  height: 100dvh;
}

.scroll-downs {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 10px;
  left: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #000;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #000;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

.scroll-downs p {
  margin: 5px 0 0 0;
  font-size: 18px;
  font-family: "DB Heavent";
  font-weight: 600;
}

.blink-soft {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.vission_main {
  background: url(../images/bg_black.webp) repeat;
  padding: 100px 0;
  background-size: contain;
}

.static-txt {
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  font-family: "DB Heavent";
  letter-spacing: 3px;
  line-height: 1.9;
}

.dynamic-txts {
  color: #fff;
  font-size: 70px;
  font-weight: 600;
  font-family: "DB Heavent";
  font-style: italic;
  line-height: 1.3;
  margin: 0 20px 0 10px;
  letter-spacing: 2px;
}

.link {
  background-color: #fff;
  border: 1px solid #fff;
  color: #000;
  padding: 4px 20px;
  font-size: 30px;
  font-family: "DB Heavent";
  border-radius: 40px;
  line-height: 1.2;
  margin: 15px auto;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

a.link:hover {
  color: #fff;
}

a.link::after {
  content: "";
  background: #000;
  border: 1px solid #fff;
  position: absolute;
  z-index: -1;
  padding: 0.85em 0.75em;
  display: block;
  border: none;
}

a.grow_skew_forward::after {
  left: -20%;
  right: -20%;
  top: 0;
  bottom: 0;
  transform: skewX(-45deg) scale(0, 1);
}

a[class^="link"]::after {
  transition: all 0.3s ease;
}

a[class^="link"]:hover::after {
  transition: all 0.3s ease-out;
}

a.link:hover::after {
  transform: skewX(-45deg) scale(1, 1);
}

.link svg {
  margin: -3px 0 0 5px;
}

.link-inner {
  border: 1px solid #000;
  background-color: transparent;
  font-size: 24px;
  padding: 4px 20px;
}

a.link-inner::after {
  border: none;
}

.link-inner:hover svg {
  fill: #fff;
}

.main_service-box {
  margin-top: 100px;
}

.flip-box {
  background-color: transparent;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  /* perspective: 1000px; */
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: auto;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateX(180deg);
}

.flip-box-front .hstack,
.flip-box-back .hstack {
  height: 400px;
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: transparent;
  padding: 0 100px;
}

.flip-box-back {
  color: #fff;
  transform: rotateX(180deg);
  position: relative;
  padding: 0 100px;
  background-size: cover !important;
}

.sv-01 .flip-box-back {
  background: url(../images/bg_main_text-01.webp) no-repeat;
}

.sv-02 .flip-box-back {
  background: url(../images/bg_main_text-02.webp) no-repeat;
}

.sv-03 .flip-box-back {
  background: url(../images/bg_main_text-03.webp) no-repeat;
}

.sv-04 .flip-box-back {
  background: url(../images/bg_main_text-03.webp) no-repeat;
}

.sv-05 .flip-box-back {
  background: url(../images/bg_main_text-05.webp) no-repeat;
}

.sv-06 .flip-box-back {
  background: url(../images/bg_main_text-06.webp) no-repeat;
}

.sv-07 .flip-box-back {
  background: url(../images/bg_main_text-07.webp) no-repeat;
}

.sv-08 .flip-box-back {
  background: url(../images/bg_main_text-08.webp) no-repeat;
}

.sv-09 .flip-box-back {
  background: url(../images/bg_main_text-09.webp) no-repeat;
}

.sv_text-active {
  position: absolute;
  top: -18%;
}

.sv-content h3 {
  font-size: 48px;
  margin-bottom: 0px;
  font-weight: 700;
  letter-spacing: 1.2px;
}

.sv-content p {
  font-size: 36px;
  line-height: 1;
}

.img-button_active img {
  position: absolute;
  bottom: 5%;
  right: 10%;
}

.box_our_project {
  background: url(../images/bg_black.webp);
}

.s-client {
  background: url(../images/bg_black.webp);
}

@keyframes slidetrack {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  height: auto;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
  padding: 50px 20px;
}

.slider .slide-track {
  animation: slidetrack 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slider .slide {
  height: auto;
  width: 250px;
}

.footer {
  background: #ececec;
  border-top: 1px solid #e2e2e2;
}

.footer h1 {
  font-family: "DBHeaventBl";
  font-weight: bolder;
  font-size: 70px;
  font-style: italic;
  line-height: 1;
}

.content-footer {
  padding: 80px 10px 80px 10px;
}

.content-footer h2 {
  font-family: "DB Heavent";
  font-size: 36px;
  font-weight: 700;
}

.content-footer h3,
.content-footer h3 a {
  color: #000;
  font-weight: normal;
}

.text-copyright {
  background-color: #000;
  padding: 10px;
}

.text-copyright p {
  color: #fff;
  text-align: center;
  margin: 0;
  font-size: 18px;
}

.categorize_s {
  background-color: #f03e58 !important;
  color: #fff !important;
  margin: 0 10px 10px 0;
  padding: 5px 12px;
}

.categorize_s span {
  padding: 5px 12px !important;
}

.categorize_c {
  background-color: #0487cb !important;
  color: #fff !important;
  margin: 0 0 10px 0 !important;
}

.date {
  color: #767676;
  font-size: 20px;
  line-height: 1;
}

.card-title {
  font-weight: bold;
  line-height: 1;
  font-family: "DB Heavent";
  letter-spacing: 0.5px;
}

.card-body {
  line-height: 1;
  font-size: 20px;
  font-family: "DB Heavent";
}

.card-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card {
  background-color: transparent;
  border: none;
}

.img-card {
  width: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}

.zoom a img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.zoom a:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/* Pulse */
@keyframes pulse {
  25% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
}
.pulse {
  display: inline-block;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.pulse:hover {
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/*---------------*/
/***** Honey *****/
/*---------------*/
figure.effect-honey {
  background-image: repeating-linear-gradient(
      0deg,
      #fff,
      #fff 10px,
      transparent 10px,
      transparent 20px,
      #fff 20px
    ),
    repeating-linear-gradient(
      90deg,
      #fff,
      #fff 10px,
      transparent 10px,
      transparent 20px,
      #fff 20px
    ),
    repeating-linear-gradient(
      180deg,
      #fff,
      #fff 10px,
      transparent 10px,
      transparent 20px,
      #fff 20px
    ),
    repeating-linear-gradient(
      270deg,
      #fff,
      #fff 10px,
      transparent 10px,
      transparent 20px,
      #fff 20px
    );
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  text-align: center;

  padding: 20px 10px 10px 10px;
  position: relative;
  overflow: hidden;
  height: 370px;
}

.heading-proj h1 {
  color: #fff;
  font-weight: bolder;
  font-style: italic;
  line-height: 0.8;
  font-size: 36px;
  font-family: "DBHeaventBl";
  margin-top: 10px;
}

.stroke {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
  font-weight: bolder;
}

.text-proj p {
  color: #fff;
  line-height: 1;
  margin-bottom: 5px;
  font-size: 16px;
  padding: 0 10px;
}

figure.effect-honey .a-project {
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0);
  padding: 0 20px;
}

figure.effect-honey .a-img-center {
  bottom: 10%;
}

figure.effect-honey .r-img-center {
  padding: 0 0 0 0;
}

figure.effect-honey:hover .a-project {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-honey .text-proj {
  position: absolute;
  bottom: 5px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0);
  width: 100%;
  left: 0;
  right: 0;
}

figure.effect-honey:hover .text-proj {
  width: 100%;
  left: 0;
  right: 0;
}

figure.effect-honey:hover .text-proj.line-twin {
  -webkit-transform: translate3d(0, -230px, 0);
  transform: translate3d(0, -230px, 0);
}

figure.effect-honey:hover .text-proj.line-single {
  -webkit-transform: translate3d(0, -260px, 0);
  transform: translate3d(0, -260px, 0);
}

figure.effect-honey.p-06:hover .text-proj.line-single {
  -webkit-transform: translate3d(0, -240px, 0);
  transform: translate3d(0, -240px, 0);
}

.effect-honey:hover {
  background-image: none;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.61);
  -webkit-box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.61);
}

.bg-pink:hover {
  background-color: #f03e58;
}

.bg-blue:hover {
  background-color: #0486c8;
}

.bg-green:hover {
  background-color: #99cb50;
}

.bg-yellow:hover {
  background-color: #fec20f;
}

.key_visual {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
}

.key_visual img {
  object-fit: cover;
  object-position: center;
  width: 80%;
  height: auto;
}

.sv_content.event-content {
  background: url(../images/bg-green.jpg);
  padding: 70px 0 100px 0;
  background-size: cover;
  background-position: 50% 80%;
}

.sv_content.dmkt-content {
  background: url(../images/bg-blue.jpg);
  padding: 70px 0;
  background-size: cover;
  background-position: 50% 80%;
  background-repeat: no-repeat;
}

.sv_content.web-content {
  background: url(../images/bg-yellow.jpg);
  padding: 70px 0;
  background-size: cover;
  background-position: 50% 80%;
  background-repeat: no-repeat;
}

.sv_content.tool-content {
  background: url(../images/bg-pink.jpg);
  padding: 70px 0;
  background-size: cover;
  background-position: 50% 80%;
  background-repeat: no-repeat;
}

.heading-top_content h2 {
  background-color: #221f1f;
  color: #fff;
  font-weight: bold;
  padding: 5px 40px;
  border-radius: 35px;
  letter-spacing: 1.2px;
  margin: 0 0 0 0;
  position: relative;
}

.sv_content {
  padding: 80px 0;
}

.sv_content-inner {
  padding: 80px 50px 100px 50px;
  background: url(../images/bg.webp);
  background-repeat: repeat;
  border-radius: 10px;
  margin-top: -25px;
}

.sv_content-inner p {
  font-size: 24px;
  font-family: "DB Heavent";
  margin-bottom: 0px;
  line-height: 1.2;
  font-weight: 700;
}

.sv_content-inner ul {
  font-size: 24px;
  font-family: "DB Heavent";
  padding: 0 0 0 15px;
  line-height: 1.2;
  font-weight: 700;
}

.ev-heading-spr h1 {
  margin-bottom: 0;
  line-height: 1.2;
}

.ev-heading-spr h1.t-80 {
  font-size: 80px;
}

.ev-heading-spr h2.t-60 {
  font-size: 60px;
}

.black-content {
  background: url(../images/bg_black.webp);
  background-repeat: repeat;
  background-size: contain;
}

.green-content {
  background-color: #99cb4f;
}

.pink-content {
  background-color: #f03e58;
}

.blue-content {
  background-color: #0a85c7;
}

.news_content {
  margin-top: 90px;
}

.ev-heading-spr_bl h1 {
  font-size: 60px;
  line-height: 1;
  font-family: "DBHeaventBl";
}

.ev-heading-spr_bl h2 {
  font-size: 40px;
  line-height: 1;
}

.ev-heading-ov_bl h1 {
  font-size: 100px;
  line-height: 1;
  font-family: "DBHeaventBl";
  margin-bottom: 20px;
}

.ev-heading-ov_bl hr {
  border-top: 5px solid #0486c8;
  opacity: 1;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 15px auto;
}

.ev-heading-ov_bl hr.line-2 {
  border-top: 5px solid #0486c8;
  opacity: 1;
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.ev-heading-ov_bl {
  margin-bottom: 80px;
}

.port-service .card-body {
  padding: 0 30px;
}

.port-service .card-body h1 {
  font-size: 60px;
  font-family: "DBHeaventBl";
}

.port-service .card-body ul {
  padding-left: 20px;
}

.port-service .card-body ul li {
  font-size: 30px;
  margin-bottom: 10px;
}

.port-service .card-footer {
  padding: 0 30px;
  background-color: transparent;
  border: none;
}

.port-service .card-footer h3 {
  font-size: 36px;
  line-height: 1;
  margin-bottom: 0;
}

.sv-inner-parallax h1 {
  font-family: "DBHeaventBl";
  margin-bottom: 0;
  line-height: 0.9;
  margin-bottom: 5px;
  font-size: 48px;
}

.sv-inner-parallax h2 {
  margin-bottom: 0;
  line-height: 0.9;
  font-size: 36px;
}

.ev-content-footer h1 {
  font-family: "DBHeaventBl";
  font-size: 80px;
  margin-bottom: 10px;
  line-height: 1;
}

.ev-content-footer h2 {
  font-family: "DBHeaventBl";
  font-size: 48px;
}

.ev-content-footer h3 {
  font-size: 48px;
  line-height: 0.9;
}

.ev-content-footer_box h1 {
  font-size: 40px;
  font-family: "DBHeaventBl";
}

.ev-content-footer_box h1 .badge {
  background-color: #99cb4f;
  padding: 2px 10px;
  vertical-align: middle;
  margin-bottom: 5px;
  margin-right: 10px;
}

.ev-content-footer_box p {
  font-size: 30px;
  line-height: 1;
  margin-left: 40px;
}

.ev-content-footer_box ul {
  padding-left: 60px;
}

.ev-content-footer_box ul li {
  margin-bottom: 0;
  line-height: 1;
  font-size: 30px;
}

.dmkt-content:nth-child(odd) .dmkt-content_detail {
  background-color: #95edf9;
}

.dmkt-content:nth-child(even) .dmkt-content_detail {
  background-color: #53daf9;
}

.dmkt-content_detail.card {
  border-radius: 0;
  padding: 10px 20px;
}

.dmkt-content_detail .card-body h1 {
  font-family: "DBHeaventBl";
  display: inline-block;
  border-bottom: 2px solid #000;
  margin-bottom: 25px;
  line-height: 0.8;
  padding-bottom: 15px;
}

.dmkt-content_detail .card-body p.text-spr-strong {
  font-size: 30px;
  line-height: 0.9;
}

.dmkt-content_detail .card-body p.text-spr-strong span {
  font-family: "DBHeaventBl";
  font-size: 50px;
  display: block;
  line-height: 1;
}

.dmkt-content_detail .card-body p.text-spr-strong i {
  font-family: "DBHeaventBl";
  font-size: 70px;
  font-style: normal;
  line-height: 0.8;
}

.dmkt-content_detail .card-body p.text-spr-strong strong {
  font-family: "DBHeaventBl";
}

.dmkt-content_detail ul {
  display: inline-block;
  text-align: left;
  font-size: 30px;
  line-height: 0.9;
}

.step-dmkt {
  position: relative;
}

.img-setp {
  position: absolute;
  top: 0;
  left: 13px;
  width: 35%;
}

.step-dmkt_inner h2 span {
  padding: 15px 20px;
  margin-bottom: 15px;
  display: inline-block;
  text-align: left;
  letter-spacing: 1.2px;
}

.step-dmkt_inner h2 i {
  font-style: normal;
  display: inline-block;
  margin-right: 30px;
  font-size: 48px;
  font-family: "DBHeaventBl";
}

.step-dmkt_inner h2.num-06 i,
.step-dmkt_inner h2.num-03 i {
  color: #0486c8;
}

.step-dmkt_inner h2.num-06 span,
.step-dmkt_inner h2.num-03 span {
  background-color: #0486c8;
  width: 30%;
}

.step-dmkt_inner h2.num-05 i,
.step-dmkt_inner h2.num-02 i {
  color: #18ade8;
}

.step-dmkt_inner h2.num-05 span,
.step-dmkt_inner h2.num-02 span {
  background-color: #18ade8;
  width: 40%;
}

.step-dmkt_inner h2.num-04 i,
.step-dmkt_inner h2.num-01 i {
  color: #6dd7f7;
}

.step-dmkt_inner h2.num-04 span,
.step-dmkt_inner h2.num-01 span {
  background-color: #6dd7f7;
  width: 55%;
}

.step-dmkt_inner h2.num-03 span {
  width: 70%;
}

.step-dmkt_inner h2.num-02 span {
  width: 80%;
}

.step-dmkt_inner h2.num-01 span {
  width: 90%;
}

.step-dmkt_inner h2 {
  background-color: transparent;
}

.ev-heading-spr_bv h1 {
  font-family: "DBHeaventBl";
  font-size: 80px;
  color: #0a85c7;
  margin-bottom: 0;
  line-height: 1;
}

.ev-heading-spr_bv h2 {
  font-size: 60px;
  line-height: 0.8;
}

.ev-heading-spr_bv h3 {
  font-size: 48px;
  font-family: "DBHeaventBl";
}

.dmkt-sv .card {
  background-color: #18ade8;
  padding: 20px;
  border-radius: 0;
}

.dmkt-sv .card h2 {
  font-family: "DBHeaventBl";
}

.dmkt-sv .card ul {
  display: inline-block;
  text-align: left;
  margin-top: 10px;
  font-size: 24px;
  line-height: 1.1;
}

.file_card-inner {
  background: url(../images/bg-dkmt-02.png);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 40px 50px 50px 50px;
}

.file_card-inner::before {
  content: "";
  background: url(../images/bg-dkmt-pin.png);
  background-repeat: no-repeat;
  width: 10%;
  height: 200px;
  position: absolute;
  right: 0;
  top: -29px;
  background-size: contain;
}

.dkmt_content-file_card h1 {
  font-family: "DBHeaventBl";
  line-height: 1;
}

.web-heading-spr h1 {
  margin-bottom: 0;
  line-height: 1;
  font-size: 48px;
}

.web-heading-spr h2 {
  margin-bottom: 0;
  line-height: 1;
  font-size: 36px;
}

.card-sv .card-body p {
  font-size: 26px;
}

.borderbt {
  border-bottom: 1px solid #fff;
  display: block;
  padding-bottom: 10px;
}

.borderbtb {
  border-bottom: 1px solid #000;
  display: block;
  padding-bottom: 10px;
}

.card-footer p {
  font-size: 24px;
  line-height: 1;
}

.card-footer p span.fw-bolder {
  letter-spacing: 1px;
}

.text-blob,
.vission_img {
  opacity: 0;
}

.text-blob.aanimate__fadeInUp,
.text-blob.animate__fadeInDown,
.vission_img.animate__fadeIn {
  opacity: 1;
}

.port-service h4 {
  font-size: 30px;
}

.subject-half {
  font-size: 70px;
  line-height: 1;
}

.subject-half span {
  letter-spacing: 2px;
}

.num-i {
  background-color: #99cb4f;
  border-radius: 50%;
  font-size: 26px;
  width: 40px;
  height: 40px;
}

.num-text_inner p {
  font-size: 30px;
  line-height: 1;
}

.lo_rea {
  border-bottom: 3px solid #99cb50;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.num-text_inner ul {
  padding: 0 0 0 20px;
  margin: 0;
}

.num-text_inner ul li {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0;
}

.document-box .card-header {
  background-color: #000;
  padding: 15px 20px;
}

.document-box .card-header h2 {
  line-height: 0.8;
  letter-spacing: 1px;
}

.document-box .card-body {
  background-color: #99cb4f;
}

.document-box .card-body ul {
  margin: 0;
}

.document-box .card-body ul li {
  font-size: 24px;
}

.card-logis {
  border: 3px solid #fff;
  border-radius: 0;
}

.card-logis .card-body {
  padding: 20px 40px 40px 40px;
}

.card-logis h2 {
  font-size: 40px;
}

.card-logis p {
  font-size: 24px;
}

.box-half {
  background-color: #eda411;
}

.box-half h1 {
  font-size: 80px;
  line-height: 1;
}

.card-text-logis {
  border-right: 2px solid #eda411;
  border-radius: 0;
}

.card-text-logis:last-child {
  border: none;
}

.card-text-logis p {
  font-size: 36px;
  line-height: 1;
}

.num-y span {
  display: flex;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background-color: #eda411;
  color: #fff;
  margin: 0 auto 20px auto;
  font-weight: bolder;
  font-family: "DBHeaventBl";
  font-size: 24px;
}

.card-text-logis ul {
  margin: 0 0 0 20px;
  padding: 0;
}

.card-text-logis ul li {
  font-size: 26px;
}

.box-half .web-heading-spr h1 {
  font-size: 60px;
}

.sale-ul,
.sale-ul-lightbule {
  list-style: none;
}

.sale-ul li,
.sale-ul-lightbule li {
  font-size: 28px;
  line-height: 1.3;
}

.sale-ul li::before {
  content: "\2022";
  padding-right: 10px;
  color: #0486c8;
  font-size: 30px;
}

.sale-ul-lightbule li::before {
  content: "\2022";
  padding-right: 10px;
  color: #95edf9;
  font-size: 30px;
}

.sale-box {
  margin-bottom: 40px;
}

.sale-box img {
  padding: 0 30px;
}

.sale-box:nth-child(even) img {
  margin-top: 100px;
}

.box-half-sale {
  background-color: #95edf9;
}

.box-half-sale {
  padding: 80px 100px;
}

.box-half-sale h1 {
  font-size: 50px;
  margin-left: 15px;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 500px;
  width: 100%;
}

.itp__carousel .each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100dvh;
}

.file_card_media-inner {
  background: url(../images/bg-media.png);
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 40px 50px 50px 50px;
}

.file_card_media-inner::before {
  content: "";
  background: url(../images/bg-dkmt-pin.png);
  background-repeat: no-repeat;
  width: 10%;
  height: 200px;
  position: absolute;
  right: 0;
  top: -29px;
  background-size: contain;
  z-index: 1;
}

.pin-black::before {
  content: "";
  background: url(../images/bg-media-pin.png);
  background-repeat: no-repeat;
  width: 10%;
  height: 200px;
  position: absolute;
  right: 0;
  top: -29px;
  background-size: contain;
  z-index: 1;
}

.media_content-file_card h1 {
  font-size: 60px;
}

.media_content-file_card p {
  font-size: 24px;
  line-height: 1.2;
}

.media_content-file_card ul {
  padding: 0 0 0 20px;
  margin-top: 0px;
}

.media_content-file_card ul li {
  margin-bottom: 0;
  font-size: 24px;
  color: #fff;
}

.react-slideshow-container .default-nav {
  background-color: #b52440;
  fill: #fff;
  width: 40px;
  height: 40px;
  margin: 0 !important;
  padding: 0;
}

.react-slideshow-container .default-nav svg {
  font-size: 20px;
}

.react-slideshow-container .nav:first-of-type {
  left: -20px;
}

.react-slideshow-container .nav:last-of-type {
  right: -20px;
}

.react-slideshow-container + ul.indicators li {
  width: 10px;
  height: 10px;
  padding: 5px 8px;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  background-color: #fff;
  width: 10px;
  height: 10px;
}

.react-slideshow-container .default-nav:hover,
.react-slideshow-container .default-nav:focus {
  background: #000;
  color: #fff;
  outline: 0;
}

.box-media .icon {
  width: 75px;
  height: auto;
  margin-right: 25px;
}

.box-media h2 {
  line-height: 1;
}

.box-media {
  border-bottom: 2px solid #f03e58;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.box-comm.box-media {
  border-bottom: 2px solid #95edf9;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.num-tool {
  background-color: #f03e58;
  color: #ffff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: "DBHeaventBl";
  margin-right: 20%;
}

.num-tool-blue {
  background-color: #95edf9;
  color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: "DBHeaventBl";
  margin-right: 20%;
}

.num-tool-blue span {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-tool h2 {
  width: 50%;
}

.box-comm h2 {
  width: 60%;
}

.box-comm .tool-list {
  width: 50%;
  font-size: 20px;
  line-height: 1;
}

.tool-list ul li {
  font-size: 20px;
  margin-bottom: 0;
  line-height: 1.2;
}

.itp__carousel .react-slideshow-container .default-nav {
  display: none;
}

.react-slideshow-wrapper .images-wrap.horizontal {
  flex-wrap: nowrap;
}

.news-filter {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
}

.sub-heading h2 {
  border-bottom: 1px solid #000;
}

.category {
  margin: 20px 0 0 0;
}

.chip {
  background-color: #000 !important;
  color: #fff !important;
}

.chip:hover,
.chip.active {
  background-color: #f03e58 !important;
  color: #fff !important;
}

.Mui-selected {
  background-color: #f03e58 !important;
  color: #fff !important;
}

.news_content-heading h1 {
  font-size: 40px;
  margin-bottom: 15px !important;
  letter-spacing: 1px;
  font-family: "Prompt", "DB Heavent", sans-serif !important;
}

.news_content-detail p,
.news_content-detail p p {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 15px;
  font-family: "Prompt", "DB Heavent", sans-serif !important;
}

.news_content-detail p img {
  width: 100%;
  height: auto;
}

.heading-contact h1 {
  font-family: "DBHeaventBl";
  font-size: 100px;
  line-height: 1;
}

.contact-text svg {
  fill: #99cb4f;
  font-size: 24px;
}

.contact-text p {
  font-size: 24px;
  line-height: 1.2;
}

.contact-text p span {
  letter-spacing: 1px;
}

.contact-text a {
  color: #fff;
  font-size: 24px;
  line-height: 1.2;
  text-decoration: underline !important;
}

.contact-text a:hover {
  color: #99cb4f;
}

.form-floating textarea {
  height: 200px !important;
}

.textareaplaceholder {
  color: rgba(33, 37, 41, 0.8);
  font-size: 24px;
  padding: 16px !important;
  line-height: 1;
  font-family: "DB Heavent";
}

.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 40px;
}

.form-floating > .form-control {
  padding: 16px;
  font-family: sans-serif;
  font-size: 18px !important;
  line-height: 1.2;
}

.form-check-input {
  margin: 0 10px 0 0;
  border: 1px solid #0a85c7;
}

.check-form_contact label {
  font-size: 24px;
}

.check-form_contact label a {
  text-decoration: underline !important;
}

.check-form_contact label a:hover {
  color: #99cb4f;
}

div:where(.swal2-container) {
  z-index: 9999 !important;
}

.newsbox .card-body {
  padding: 20px 0;
}

.newsbox h3 {
  font-size: 18px;
  line-height: normal;
  font-family: "Prompt", "DB Heavent", sans-serif !important;
  letter-spacing: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.2;
  white-space: normal !important;
}

.categorize_c {
  width: fit-content;
  padding: 5px 12px;
}

.categorize_c span {
  word-wrap: break-word;
  overflow: unset;
  text-wrap: balance;
  padding: 5px 12px;
  line-height: 1;
  text-align: center;
}

.form-control-lg,
.form-floating > .form-control {
  font-size: 24px;
}

.floating-btn {
  position: relative !important;
}

.floating {
  padding: 10px !important;
  box-shadow: 0px 4px 20px rgb(170 180 190 / 30%) !important;
}

.floating:hover {
  background-color: #005694 !important;
}

.totop {
  box-shadow: 0px 4px 20px rgb(170 180 190 / 30%) !important;
  background-color: #c2e0ff !important;
}

.totop:hover {
  background-color: #99ccf3 !important;
}

.linkfab {
  box-shadow: 0px 4px 20px rgba(35, 35, 35, 0.3) !important;
  background-color: #eda411 !important;
}

.linkfab:hover {
  background-color: #000 !important;
}

.linkfab:hover svg {
  color: #eda411 !important;
}

.h200 {
  height: 250px;
}

.gdpr {
  position: fixed;
  bottom: 0;
  background-color: #000;
  padding: 10px;
  z-index: 9999;
  width: 100%;
}

.gdpr-box p {
  color: #fff;
}

.gdpr-box p {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.2;
}

.gdpr-box p a {
  padding: 0 5px;
}

.gdpr-box p a:hover {
  color: #95edf9;
}

.content-heading h1 {
  font-size: 60px;
  text-transform: uppercase;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}

.c-text {
  font-size: 26px;
  font-weight: lighter;
  font-family: "DB Heavent";
  line-height: 1.2;
}

.c-content ul {
  margin-left: 20px;
}

.c-content ul li .fw-bold {
  letter-spacing: 1.1px;
}

.proj {
  margin-top: 150px;
  overflow: hidden;
}

.project-heading h1 {
  font-size: 120px;
  line-height: 0.8;
  font-weight: bold;
  font-family: "DBHeaventBl";
  letter-spacing: 1.2px;
  margin-bottom: 35px;
  text-transform: uppercase;
}

.badge_box {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 35px;
}

.badge_box a .badge {
  font-size: 28px;
  border: 1px solid #231f20;
  background-color: #fff;
  font-family: "DB Heavent";
  padding: 9px 25px;
  display: block;
}

.badge_box a:hover .badge {
  background-color: #000 !important;
  color: #fff !important;
}

.project-list li {
  font-size: 26px;
  line-height: 1.2;
}

.project_content {
  padding: 100px 0;
}

.project_content-inner h2 {
  font-size: 60px;
  font-weight: bold;
  font-family: "DBHeaventBl";
  line-height: 1;
}

.project_content-inner p {
  font-size: 26px;
  line-height: 1.2;
}

.text-unset {
  text-transform: unset !important;
}

.top_banner-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  flex-direction: column;
  margin: 150px 0;
  text-align: center;
}

.top_banner-inner h3 {
  font-family: "DB Heavent";
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 50px;
}

.top_banner-inner h2 {
  font-family: "DBHeaventBl";
  font-size: 52px;
  line-height: 1;
}

.map-banner {
  margin-bottom: 200px;
}

.nav-network {
  flex-direction: column;
}

.nav-network .nav-link {
  color: #000;
  font-size: 80px;
  font-family: "DB Heavent";
  transition: all 1s;
  line-height: 1.2;
  text-transform: uppercase;
}

.nav-network .nav-link:hover,
.nav-network .is-current .nav-link {
  transition: all 0.5s;
  transform: translateX(30px);
  font-family: "DBHeaventBl";
  font-weight: bold;
}

.nav-network .nav-link.asia:hover,
.nav-network .is-current .nav-link.asia {
  color: #ee4156;
}

.nav-network .nav-link.sea:hover,
.nav-network .is-current .nav-link.sea {
  color: #96c950;
}

.nav-network .nav-link.europe:hover,
.nav-network .is-current .nav-link.europe {
  color: #ffc012;
}

.nav-network .nav-link.usa:hover,
.nav-network .is-current .nav-link.usa {
  color: #0587cf;
}

.list-network_link {
  display: flex;
  padding: 1rem 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  z-index: 10;
  width: 100%;
  flex-direction: column;
  margin-bottom: 300px;
}

.network_detail-inner {
  background-color: #e7e6e6;
  padding: 25px;
  margin-top: 150px;
}

#japan .network_detail-inner {
  margin-top: 100px;
}

.network_heading-place {
  margin-top: -17%;
  line-height: 1;
}

.network_heading-place h1 {
  font-family: "DBHeaventBl";
  font-size: 70px;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 0;
}

.network_heading-place h4 {
  font-family: "DB Heavent";
  font-size: 48px;
  line-height: 1;
}

.network_detail-inner .card-body {
  padding: 0;
}

.network-img {
  width: 60% !important;
}

.network_detail-inner .card-footer {
  background-color: transparent;
  border: none;
  padding: 0 0 0 30px;
}

.network_detail-inner p,
.network_detail-inner a {
  margin-bottom: 10px;
  font-family: "DB Heavent";
  font-size: 24px;
  line-height: 1;
}

.network_detail-inner p span,
.network_detail-inner a span {
  font-weight: 700;
}

.network_detail-inner a:hover {
  color: #b52440;
}

.network_detail-inner p:last-child {
  margin-bottom: 0;
}

#usa.network_detail {
  margin-bottom: 300px;
}

.network_box-detail {
  overflow: hidden;
}

.story1 {
  position: relative;
  height: 100vh;
}

.story1 .section {
  text-align: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  clip: rect(0, auto, auto, 0);
}

.story1.h80 {
  height: 80vh;
}

.story1.h80 .section {
  height: 80vh;
}

.story1 .section .fixed {
  /* position: fixed; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.heading-ban_story h1 {
  font-size: 72px;
  margin-bottom: 30px;
}

.heading-ban_story h2 {
  font-size: 40px;
}

.story1 .section.gray-content {
  background: url(../images/bg_story.png) no-repeat;
  color: #000;
  top: 0;
  z-index: 1;
  background-size: contain;
  background-position: center center;
}

.story1 .section.black-content {
  padding: 50px 0;
}

.field {
  position: absolute;
  bottom: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.field p {
  color: #949494;
  font-size: 30px;
  margin-bottom: 0;
}

.scroll {
  width: 18px;
  height: 18px;
  position: relative;
  animation: down 1.5s infinite;
  -webkit-animation: down 1.5s infinite;
}

.scroll::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border-left: 2px solid #949494;
  border-bottom: 2px solid #949494;
  transform: rotate(-45deg);
}

@keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translate(0);
  }
}
@-webkit-keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translate(0);
  }
}

.overviwe-bg_inner {
  position: relative;
}

.overviwe-inner_img {
  position: relative;
  z-index: 1;
}

.overviwe-inner_detail {
  background: url(../images/bg_story_03.png) no-repeat;
  z-index: 2;
  position: absolute;
  width: 50%;
  right: 5%;
  height: 90%;
  color: #000;
  background-size: contain;
  background-position: center center;
  padding: 180px 60px 130px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overviwe_detail {
  gap: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: left;
  text-align: left;
}

.overviwe_detail-subject {
  font-size: 28px;
  font-weight: 900;
  width: 30%;
  line-height: 1;
}

.overviwe_detail-content {
  font-size: 28px;
  width: 70%;
  line-height: 1;
}

.overviwe_detail-content span {
  font-weight: 900;
}

.story {
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
}

.story2 {
  position: relative;
}

.story2 .container-fluid {
  overscroll-behavior: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
}

.panel.align-top {
  align-items: flex-start;
}

.panel h1 {
  font-size: 50px;
  line-height: 0.9;
}

.panel {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.5em;
  text-align: center;
  color: #000;
  position: relative;
  box-sizing: border-box;
  padding: 60px;
}

.img-wrapper_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
  flex-direction: column;
}

.story3 {
  height: auto;
  position: relative;
}

.heading-big {
  margin-bottom: 30px;
}

.heading-big h1 {
  font-size: 200px;
  margin-bottom: 0;
  line-height: 1;
}

.heading-big h3 {
  font-size: 120px;
  font-family: "DB Heavent";
  line-height: 1;
}

.story3-text_normal p {
  font-size: 48px;
  line-height: 1;
}

.story3-text_normal h4 {
  margin-top: 40px;
  text-align: center;
  font-size: 55px;
  line-height: 1;
}

.story4 .sv_content {
  height: auto;
  display: flex;
  align-items: center;
}

.story4-heading h1 {
  text-transform: uppercase;
  font-size: 65px;
  line-height: 1;
  margin-bottom: 25px;
}

.story4-content p {
  font-size: 40px;
  line-height: 1;
}

.story4-content ul {
  list-style: none;
  padding-left: 0px;
}

.story4-content ul li {
  font-size: 40px;
  line-height: 1.4;
}

.story5 .sv_content {
  padding: 100px 0;
}

.story5-heading_banner h1 {
  font-size: 80px;
  line-height: 0.8;
  margin-bottom: 10px;
}

.story5-heading_banner h2 {
  font-size: 60px;
  margin-top: 10px;
}

.philosophy {
  font-size: 80px;
  margin-bottom: 80px;
}

.text-itp_color h1 {
  font-size: 60px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.c-story_5 {
  padding: 0;
}

.c-story_5 .card {
  padding: 50px;
}

.c-story_5 .card h3 {
  font-size: 80px;
  font-weight: bold;
  margin: 20px 0;
}

.c-story_5 .card img {
  height: 400px;
  margin-bottom: 20px;
}

.c-story_5 .card p {
  font-size: 48px;
}

.story_5-bt {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.story_5-bt h2 {
  font-size: 60px;
  letter-spacing: 1px;
}

.story6 {
  padding: 150px 0;
  overflow-x: hidden;
}

.timeline ul {
  padding: 0;
}

.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding: 0 0 150px 0;
  background: #0a85c7;
}

.timeline ul li::after {
  content: "";
  position: absolute;
  left: -265%;
  top: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 2;
  background: #96c950;
  border: 7px solid #fff;
}

.timeline ul li:first-child::after {
  margin-top: 0;
}

.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 550px;
  padding: 0;
  border-radius: 5px;
  display: flex;
  align-items: start;
  text-align: left;
  justify-content: flex-start;
  top: 0;
}

.timeline ul li div time {
  position: absolute;
  width: 550px;
  height: 40px;
  top: 0;
  border-radius: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
  letter-spacing: 1px;
  font-size: 46px;
  font-weight: bold;
}

.timeline ul li div div {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.timeline ul li div .discovery,
.timeline ul li:nth-of-type(even) .-wq .discovery {
  margin-right: 10px;
  text-align: left;
  justify-content: center;
  align-items: flex-start;
}

.timeline ul li div .discovery h4 {
  font-size: 30px;
  font-family: "DB Heavent";
}

.timeline ul li div .discovery p {
  font-size: 26px;
  font-family: "DB Heavent";
  line-height: 1;
  margin-bottom: 5px;
}

.timeline ul li:nth-of-type(odd) > div {
  left: 245px;
}

.timeline ul li:nth-of-type(even) > div {
  left: -789px;
  justify-content: end;
}

.timeline ul li:nth-of-type(even) .-wq {
  left: 245px;
  top: 0;
  bottom: auto;
  position: absolute;
}

.timeline ul li:nth-of-type(even) > div .discovery {
  align-items: flex-end;
  text-align: end;
  margin-right: 0;
}

.timeline ul li:nth-of-type(even) div time {
  justify-content: right;
}

.timeline ul li:nth-of-type(even) .-wq time {
  justify-content: left;
}

.timeline ul li::before {
  content: "";
  border-top: 5px solid #ee4156;
  width: 150px;
  height: 5px;
  position: absolute;
  top: 18px;
  left: 30px;
}

.timeline ul li .-wq::before {
  content: "";
  border-top: 5px solid #ee4156;
  width: 150px;
  height: 5px;
  position: absolute;
  top: 18px;
  left: -215px;
}

.timeline ul li:first-child::before {
  top: 18px;
}

.timeline ul li:nth-of-type(even)::before {
  top: 18px;
  left: auto;
  right: 30px;
}

/* .timeline ul li div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.timeline ul li:nth-of-type(odd) div {
  transform: translate3d(100px, -10px, 0) rotate(10deg);
}

.timeline ul li:nth-of-type(even) div {
  transform: translate3d(-100px, -10px, 0) rotate(10deg);
} */

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}

.timeline ul {
  margin-bottom: 5px;
}

.timeline-footer {
  margin-top: 50px;
  font-size: 80px;
  text-align: center;
}

.timeline-end div {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding: 0 0 20px 0;
  background: #0a85c7;
}

.timeline-end div:nth-child(2) {
  padding: 0 0 30px 0;
  margin-top: 10px;
}

.timeline-end div:nth-child(3) {
  padding: 0 0 30px 0;
  margin-top: 10px;
}

.timeline-end div:nth-child(4) {
  padding: 0 0 30px 0;
  margin-top: 10px;
}

.timeline-end div:nth-child(5) {
  padding: 0 0 30px 0;
  margin-top: 10px;
}

.timeline-end div:nth-child(6) {
  padding: 0 0 20px 0;
  margin-top: 10px;
}

.story-page {
  overflow: hidden;
}

.box_login form {
  background-color: #fff;
  padding: 50px;
  border-radius: 10px;
  gap: 20px 0;
  display: flex;
  flex-direction: column;
}

.box_login form h4 {
  font-weight: 700;
}

figure.effect-honey.hover .text-proj.line-twin {
  transform: translate3d(0, 95px, 0);
  top: 0px;
}

figure.effect-honey.hover .text-proj.line-single {
  transform: translate3d(0, 65px, 0);
  top: 0px;
}

figure.effect-honey.hover .a-project {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-honey.hover .text-proj {
  width: 100%;
  left: 0;
  right: 0;
}

.effect-honey.hover {
  background-image: none;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.61);
  -webkit-box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.61);
  transition: 0.35s ease-in-out;
}

.hover.bg-pink {
  background-color: #f03e58;
}

.hover.bg-blue {
  background-color: #0486c8;
}

.hover.bg-green {
  background-color: #99cb50;
}

.hover.bg-yellow {
  background-color: #fec20f;
}

.top-w {
  position: fixed;
  bottom: 170px;
  right: 8px;
}

.icon-web_printing {
  position: relative;
}

.n-ppost {
  background-image: url(../images/b-icon-link.png);
  width: 65px;
  height: 65px;
  position: absolute;
  right: 0;
  background-repeat: no-repeat;
  background-size: 65px;
  z-index: 999;
}

.n-ppost:hover {
  background-image: url(../images/b-icon-link_hover.png);
  width: 65px;
  height: 65px;
  background-size: 65px;
}

.n-ppost-name {
  top: 100%;
  left: auto;
  margin-top: 4px;
  width: 190px;
  opacity: 0;
  -webkit-transform: translate3d(0, -15px, 0);
  transform: translate3d(0, -15px, 0);
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.4;
  visibility: hidden;
  pointer-events: none;
  background-color: #db2323;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  font-size: 27px;
  height: 40px;
  padding: 3px 50px 3px 15px;
  position: absolute;
  right: 7px;
  top: 9px;
  transition: all .5s;
  transform : translateX(-10px);
}

.n-ppost:hover + .n-ppost-name {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

@media screen and (max-width: 1600px) {
  .flip-box-front,
  .flip-box-back {
    padding: 0 50px;
  }
  .flip-box-back .img-text img {
    width: 90%;
    height: auto;
    top: -25%;
  }

  .img-button_active img {
    right: 5%;
  }

  .flip-box-back .img-button_active img {
    bottom: 15%;
  }
  .overviwe_detail-content,
  .overviwe_detail-subject {
    font-size: 18px;
    line-height: 1.1;
  }
}

@media screen and (max-width: 1366px) {
  .flip-box-back .img-text img {
    top: -15%;
  }
  .flip-box-front .img-text img {
    width: 80%;
    max-width: 100%;
    height: auto;
  }

  .flip-box-back .img-text img {
    width: 80%;
    max-width: 100%;
    height: auto;
  }
  figure.effect-honey {
    height: 340px;
  }
  figure.effect-honey:hover .text-proj.line-twin {
    transform: translate3d(0, -180px, 0);
  }
  figure.effect-honey:hover .text-proj.line-single {
    transform: translate3d(0, -230px, 0);
  }
  figure.effect-honey.p-03:hover .text-proj.line-single,
  figure.effect-honey.p-05:hover .text-proj.line-single,
  figure.effect-honey.p-06:hover .text-proj.line-single,
  figure.effect-honey.p-09:hover .text-proj.line-single,
  figure.effect-honey.p-11:hover .text-proj.line-single {
    transform: translate3d(0, -210px, 0);
  }
  figure.effect-honey.p-10:hover .text-proj.line-twin,
  figure.effect-honey.p-12:hover .text-proj.line-twin {
    transform: translate3d(0, -200px, 0);
  }
  .img-setp {
    position: absolute;
    top: -4px;
    left: -20px;
    width: 47%;
  }
  .footer h1 {
    font-size: 60px;
  }
  .file_card-inner::before {
    top: -33px;
  }
  .dmkt-content_detail .card-body p.text-spr-strong {
    font-size: 26px;
  }

  .heading-ban_story h1,
  .story5-heading_banner h1,
  .philosophy,
  .c-story_5 .card h3 {
    font-size: 60px;
  }
  .heading-ban_story h2,
  .story3-text_normal p,
  .c-story_5 .card p,
  .story_5-bt h2 {
    font-size: 36px;
  }
  .text-itp_color h1 {
    font-size: 48px;
    line-height: 1;
  }
  .field p {
    font-size: 24px;
  }

  .overviwe_detail-content,
  .overviwe_detail-subject {
    font-size: 18px;
    line-height: 1.1;
  }

  .overviwe_detail.mb-3,
  .overviwe_detail.mb-4 {
    margin-bottom: 10px !important;
  }

  .heading-big h1 {
    font-size: 150px;
  }
  .heading-big h3 {
    font-size: 100px;
  }
  .story4-content p,
  .story4-content ul li {
    font-size: 30px;
  }

  .c-story_5 .card img {
    height: 235px;
  }
  .timeline ul li div .discovery p,
  .timeline ul li div .discovery h4 {
    font-size: 20px;
    line-height: 1.1;
  }
}

@media screen and (max-width: 1180px) {
  .embla__slide__img {
    object-fit: contain !important;
  }
  .menu ul li {
    margin: auto 20px;
  }
  .dmkt-sv .card h2 {
    line-height: 1;
  }
  .card-body {
    padding: 10px 0;
  }
  figure.effect-honey {
    height: 300px;
  }
  .heading-proj h1 {
    font-size: 30px;
    margin-top: 0px;
  }

  figure.effect-honey.hover .text-proj.line-twin {
    transform: translate3d(0, 75px, 0);
    top: 0px;
  }

  figure.effect-honey.hover .text-proj.line-single {
    transform: translate3d(0, 50px, 0);
    top: 0px;
  }

  figure.effect-honey.hover .a-project {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  figure.effect-honey.hover .text-proj {
    width: 100%;
    left: 0;
    right: 0;
  }

  .effect-honey.hover {
    background-image: none;
    box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.61);
    -webkit-box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.61);
    -moz-box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.61);
    transition: 0.25s ease-in-out;
  }

  .hover.bg-pink {
    background-color: #f03e58;
  }

  .hover.bg-blue {
    background-color: #0486c8;
  }

  .hover.bg-green {
    background-color: #99cb50;
  }

  .hover.bg-yellow {
    background-color: #fec20f;
  }

  .flip-box.hover .flip-box-inner {
    transform: rotateX(180deg);
  }
  .each-slide-effect > div {
    height: 405px;
  }
  .itp__carousel .each-slide-effect > div {
    height: 100vh;
    width: 100%;
    background-size: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }
  .news_content {
    margin-top: 65px;
  }
  .port-service .card-body ul li,
  .port-service .card-footer h3,
  .ev-content-footer_box p,
  .ev-content-footer_box ul li,
  .sale-ul li,
  .sale-ul-lightbule li,
  .port-service h4,
  .card-text-logis p,
  .sub-heading h2 {
    font-size: 24px;
  }
  .sale-ul li,
  .sale-ul-lightbule li {
    font-size: 20px;
    line-height: 1;
  }
  .ev-content-footer h3 {
    font-size: 36px;
  }
  .ev-heading-spr h2.t-60,
  .media_content-file_card h1,
  .box-half h1 {
    font-size: 40px;
  }
  .file_card_media-inner {
    background-size: cover;
  }
  .box-half-sale h1,
  .port-service .card-body h1 {
    font-size: 36px;
    line-height: 1;
  }
  .port-service img {
    padding: 0 !important;
  }
  .Me0-heading-spr br {
    display: none;
  }
  .link {
    width: 100px;
    text-align: center;
    margin-left: 50px;
  }
  .gdpr-box p {
    font-size: 14px;
  }

  .itp__carousel .each-slide-effect > div {
    height: 80vh;
  }
  section.carousel {
    height: 80dvh;
  }
  .key_visual {
    height: 60dvh;
  }
  .categorize_c {
    width: 120px;
  }
  .categorize_c span {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 12px;
    padding-right: 12px;
    white-space: nowrap;
  }
  .news_content-heading .categorize_c {
    width: fit-content;
  }
  .project-heading h1 {
    font-size: 90px;
  }

  .nav-network .nav-link {
    font-size: 60px;
    line-height: 1;
  }
  .network_heading-place h1 {
    font-size: 50px;
  }
  .network_heading-place h4 {
    font-size: 40px;
  }
  .network_detail-inner p,
  .network_detail-inner a {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .overviwe_detail-content,
  .overviwe_detail-subject {
    font-size: 16px;
    line-height: 1;
  }
  .panel h1,
  .story3-text_normal h4 {
    font-size: 36px;
  }
  .heading-big h1 {
    font-size: 90px;
  }
  .heading-big h3 {
    font-size: 60px;
  }
  .heading-ban_story h2,
  .story3-text_normal p,
  .c-story_5 .card p,
  .story_5-bt h2 {
    font-size: 30px;
  }
  .heading-ban_story h1,
  .story5-heading_banner h1,
  .philosophy,
  .c-story_5 .card h3 {
    font-size: 48px;
  }

  .story5-heading_banner h2 {
    font-size: 40px;
  }
  .timeline ul li div,
  .timeline ul li div time {
    width: 300px;
  }
  .timeline ul li:nth-of-type(even) > div {
    left: -529px;
    justify-content: end;
  }
  .timeline ul li div .discovery p,
  .timeline ul li div .discovery h4 {
    font-size: 22px;
    line-height: 1.1;
  }
}

@media screen and (max-width: 820px) {
  .each-slide-effect > div {
    height: 595px;
  }
  section.carousel {
    height: 80dvh;
  }
  .itp__carousel .each-slide-effect > div {
    height: 80vh;
  }

  .key_visual {
    height: 60dvh;
  }

  .text-heading_img img {
    width: 100%;
    height: auto;
    margin-right: 0 !important;
  }
  .flip-box-back .img-text img {
    width: 90%;
  }
  .flip-box-front .hstack,
  .flip-box-back .hstack {
    height: 350px;
  }
  .flip-box-front,
  .flip-box-back {
    padding: 0 20px;
  }
  .flip-box-back .img-text img {
    top: 0;
    position: relative;
  }
  .sv-content h3,
  .sv-content p {
    font-size: 30px;
  }
  .flip-box-back .img-button_active img {
    bottom: 20px;
    right: 20px;
  }
  .img-button_active img {
    bottom: 0;
  }
  .content-footer {
    padding: 20px;
  }
  .content-footer {
    text-align: left;
  }
  .text {
    width: 70%;
    padding-left: 50px;
    margin-bottom: 50px;
  }
  .flip-box-back .img-text {
    width: 80%;
  }

  .contact-text h1 {
    font-size: 30px;
  }

  .ev-heading-spr_bv h3 {
    font-size: 36px;
  }

  .mt-5 {
    margin-top: 30px !important;
  }

  .file_card-inner {
    background-size: cover;
  }
  .ev-heading-spr_bl h1,
  .ev-content-footer h1,
  .ev-heading-spr h1.t-80 {
    font-size: 50px;
  }

  .ev-heading-spr_bl h2,
  .ev-content-footer h2,
  .ev-heading-spr h2.t-60,
  .media_content-file_card h1,
  .box-half h1 {
    font-size: 36px;
    line-height: 1;
  }
  .ev-content-footer h3 {
    font-size: 24px;
  }
  .ev-content-footer_box h1 {
    font-size: 36px;
  }
  .web-heading-spr h1 {
    font-size: 40px;
  }
  .static-txt {
    font-size: 40px;
  }
  .dynamic-txts {
    font-size: 60px;
  }
  .badge_box a .badge {
    font-size: 18px;
  }
  .project-heading h1 {
    font-size: 70px;
  }

  .list-network {
    position: sticky;
    top: 25px;
    z-index: 90;
  }
  .list-network_link {
    align-items: center;
    margin-bottom: 20px;
    background-color: rgba(222, 222, 222, 0.9);
    border-radius: 10px;
    box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.21);
  }
  .nav-network {
    flex-direction: row;
  }
  .nav-network .nav-link:hover,
  .nav-network .is-current .nav-link {
    transform: translateX(0);
  }
  .nav-network .nav-link {
    font-size: 50px;
    color: #000;
    font-weight: bold;
  }
  .timeline ul li {
    margin-left: 20px;
  }
  .timeline ul li div {
    width: calc(100vw - 91px);
  }
  .timeline ul li:nth-of-type(even) > div {
    left: 245px;
  }
  .story-page {
    overflow: hidden;
  }
  .story1 .section .fixed {
    flex-direction: column;
    position: relative;
  }
  .overviwe-bg_inner {
    position: absolute;
    top: 0;
  }
  .overviwe-inner_detail {
    padding: 20px 45px;
    width: 95%;
    right: auto;
    bottom: auto;
    height: 100%;
    top: 50px;
  }
  .overviwe_detail-content,
  .overviwe_detail-subject {
    font-size: 20px;
    line-height: 1;
  }
  .img-wrapper_inner {
    padding: 0;
  }
  .story4-content div {
    justify-content: flex-start !important;
  }
  .c-story_5 .card img {
    height: auto;
  }
  .story5-heading_banner h2 {
    font-size: 30px;
  }
  .timeline ul li:nth-of-type(even) > div {
    justify-content: flex-start;
  }
  .timeline ul li:nth-of-type(even) > div .discovery {
    margin-right: 10px;
    text-align: left;
    justify-content: center;
    align-items: flex-start;
  }
  .timeline ul li:nth-of-type(even)::before {
    top: 18px;
    left: 30px;
    right: auto;
  }
  .timeline ul li:nth-of-type(even) div time {
    justify-content: left;
  }
  .timeline ul li:nth-of-type(even) .-wq {
    position: relative;
    margin-top: 25px;
  }
  .timeline-end div {
    margin-left: 20px;
  }
  .timeline-footer {
    font-size: 36px;
    text-align: left;
    margin-left: 25px;
  }
}

@media screen and (max-width: 767px) {
  .header .px-5 {
    padding: 0 15px !important;
  }
  .mo-logo {
    width: 100%;
    height: auto;
  }
  .embla {
    --slide-height: 80vh !important;
  }
  .scroll-downs {
    display: none;
  }
  section.carousel {
    height: 65dvh;
  }
  .vission_main img {
    width: 25%;
    height: auto;
  }
  .static-txt {
    line-height: 1;
    font-size: 20px;
  }
  .flip-box-back .img-text img {
    width: 100%;
  }
  .v_text {
    align-items: center;
    text-align: center;
  }
  .sv-content h3,
  .sv-content p {
    font-size: 22px;
  }
  .img-button_active img {
    width: 35%;
    position: relative;
    margin: 0 auto;
    display: block;
  }
  .flip-box-front .hstack {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .flip-box-front .hstack div {
    width: 100%;
  }
  .flip-box-front .img-text img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  .flip-box-back .img-button_active img {
    position: absolute;
    width: 35%;
  }
  .flip-box-back .img-text {
    width: 100%;
  }
  .main_service-box {
    margin-top: 50px;
  }
  figure.effect-honey {
    height: 425px;
  }
  .text-proj p {
    font-size: 20px;
  }
  .heading-proj h1 br {
    display: none;
  }
  .heading-proj h1 {
    font-size: 36px;
    margin-top: 0px;
  }
  figure.effect-honey.hover .text-proj.line-twin {
    transform: translate3d(0, 55px, 0);
    top: 0px;
  }
  figure.effect-honey.p-12.hover .text-proj.line-twin {
    transform: translate3d(0, 80px, 0);
    top: 0px;
  }
  .text {
    width: 100%;
    padding-left: 0;
    margin-bottom: 20px;
  }
  .footer h1 {
    font-size: 40px;
  }
  .slider {
    padding: 30px 20px;
  }
  .vission_img {
    opacity: 1;
  }
  .key_visual {
    height: 40dvh;
  }
  .each-slide-effect > div {
    height: 80vh;
  }
  .heading-top_content h2 {
    text-align: center;
    font-size: 24px;
  }
  .sv_content-inner {
    padding: 50px 20px;
  }
  .sv_content.dmkt-content,
  .sv_content.event-content,
  .sv_content.web-content,
  .sv_content.tool-content {
    padding: 70px 15px;
  }
  .sv_content-inner p,
  .sv_content-inner ul {
    font-size: 20px;
  }
  .ev-heading-spr_bl h2,
  .ev-content-footer h2,
  .ev-heading-spr h2.t-60,
  .media_content-file_card h1 {
    font-size: 24px;
  }
  .img-setp {
    position: absolute;
    top: -4px;
    left: 0;
    width: 60%;
  }
  .step-dmkt_inner h2 span {
    font-size: 16px;
    line-height: 1;
    letter-spacing: normal;
  }
  .step-dmkt_inner h2 i {
    font-size: 36px;
    margin-right: 10px;
  }
  .ev-heading-spr_bv h1,
  .sv-inner-parallax h1,
  .ev-heading-spr_bl h1,
  .ev-heading-spr h1.t-80,
  .box-half .web-heading-spr h1 {
    font-size: 40px;
  }
  .ev-heading-spr_bv h2,
  .ev-content-footer h1 {
    font-size: 30px;
  }
  .dkmt_content-file_card .position-absolute {
    position: relative !important;
  }
  .file_card_media-inner {
    padding: 40px 20px;
  }
  .each-slide-effect > div {
    height: 40vh;
    width: 100%;
    background-size: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }
  .itp__carousel .each-slide-effect > div {
    height: 65vh;
    width: 100%;
    background-size: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }
  .heading-sale {
    flex-direction: column;
    justify-content: center;
  }
  .ev-heading-spr_bl br {
    display: none;
  }
  .heading-sale .d-inline-block {
    display: block !important;
    width: 100%;
  }
  .heading-sale h1,
  .heading-sale h2 {
    margin-left: 30px;
  }
  .sale-box:nth-child(even) img {
    margin-top: 0;
  }
  .box-half-sale {
    padding: 80px 30px;
  }
  .card-text-logis {
    border: none;
    border-bottom: 2px solid #eda411;
  }
  .card-text-logis .py-5 {
    padding: 20px !important;
  }
  .box-media {
    display: block !important;
  }
  .box-tool h2,
  .box-comm .tool-list,
  .box-comm h2 {
    width: 100%;
    margin: 15px 0 10px 0 !important;
  }
  .num-tool-blue {
    width: 30px;
    height: 30px;
  }
  .heading-contact h1 {
    font-size: 60px;
  }
  .gdpr-box {
    flex-direction: column;
  }
  .gdpr .ms-auto {
    margin: 0 auto !important;
  }
  .link {
    width: 50%;
    margin: 25px auto;
    font-size: 20px;
  }
  .gdpr .link {
    width: auto;
  }
  .proj {
    margin-top: 100px;
  }
  .proj .img-fluid {
    margin-bottom: 15px;
  }
  .project-list li {
    font-size: 18px;
  }
  .project-list ul {
    padding-left: 15px;
  }
  .badge_box {
    margin-bottom: 20px;
  }
  .project_content {
    padding: 50px 15px 100px 15px;
  }
  .project-heading h1 {
    font-size: 60px;
    margin: 20px 0;
  }
  .project_content-inner.h-25 {
    height: auto !important;
  }
  .project-list p br {
    display: none;
  }
  .top_banner-inner h3 {
    font-size: 36px;
  }
  .top_banner-inner h2 {
    font-size: 44px;
  }
  .nav-network .nav-link {
    font-size: 20px;
  }
  .network_heading-place {
    margin: 0 0 15px 0;
    text-align: center !important;
  }
  .network_detail-inner .card-footer {
    padding: 0;
  }
  .network_detail-inner {
    margin-top: 50px;
  }
  .heading-ban_story h1,
  .story5-heading_banner h1,
  .philosophy,
  .c-story_5 .card h3 {
    font-size: 30px;
  }
  .heading-ban_story h2,
  .story3-text_normal p,
  .c-story_5 .card p {
    font-size: 20px;
  }
  .story1 .section.black-content {
    padding: 50px 0;
    height: 100%;
  }
  .overviwe_detail-content,
  .overviwe_detail-subject {
    font-size: 16px;
    line-height: 1;
  }
  .overviwe_detail.mb-3,
  .overviwe_detail.mb-4 {
    margin-bottom: 0px !important;
  }
  .overviwe_detail-content p br {
    display: none;
  }

  .overviwe_detail {
    gap: 15px;
  }
  .overviwe-inner_detail {
    padding: 50px 45px 20px 20px;
    background-position: 100%;
    background-size: cover;
    height: 90%;
    z-index: 1;
  }
  .overviwe-bg_inner {
    position: absolute;
    top: -45px;
  }
  .panel {
    padding: 30px 10px;
  }
  .heading-big h1 {
    font-size: 60px;
  }
  .heading-big h3,
  .story4-heading h1 {
    font-size: 30px;
    line-height: 1;
    margin-bottom: 10px;
  }
  .panel h1,
  .story3-text_normal h4,
  .story4-content p,
  .story4-content ul li {
    font-size: 20px;
  }
  .story4 .sv_content {
    height: auto;
    padding: 30px 0;
  }
  .timeline ul li::before,
  .timeline ul li .-wq::before {
    width: 50px;
  }
  .timeline ul li:nth-of-type(odd) > div,
  .timeline ul li:nth-of-type(even) > div,
  .timeline ul li:nth-of-type(even) .-wq {
    left: 100px;
  }
  .timeline ul li .-wq::before {
    left: -70px;
  }
  .timeline ul li div {
    width: calc(100vw - 150px);
  }

  .vission_main {
    padding: 25px 0;
  }
  .dynamic-txts {
    font-size: 24px;
    opacity: 1;
  }
  .story1.h80,
  .story1.h80 .section {
    height: 60vh;
  }
  .story3 {
    padding: 10px 0;
  }
  .philosophy img {
    width: 20%;
  }
  .philosophy {
    margin-bottom: 20px;
  }
  .ev-heading-ov_bl h1 {
    font-size: 60px;
  }

  .ev-heading-ov_bl hr {
    width: 35%;
  }

  .ev-heading-ov_bl hr.line-2 {
    width: 15%;
  }

  .ev-heading-ov_bl {
    margin-bottom: 40px;
  }
  .brake {
    display: block;
  }
  .c-story_5 .card p,
  .heading-ban_story h2,
  .story3-text_normal p {
    font-size: 24px;
  }
  .c-story_5 .card img {
    width: 60%;
  }
  .c-story_5 .card {
    padding: 20px;
  }
  .c-story_5 .card h3 {
    margin: 5px 0;
  }
  .story6 {
    padding: 10px 0 50px 0;
  }
  .top_banner-inner {
    margin: 150px 0 50px 0;
  }
  .map-banner {
    margin-bottom: 50px;
  }
  .network-img {
    display: block;
  }
  .text-itp_color h1 {
    font-size: 36px;
  }
  .story5 .sv_content {
    padding: 50px 0;
  }
  .top-w {
    bottom: 155px !important;
  }
  .top-t {
    bottom: 30px !important;
  }
}

@media screen and (max-width: 380px) {
  .overviwe_detail-content,
  .overviwe_detail-subject {
    font-size: 14px;
    line-height: 1;
  }
}
