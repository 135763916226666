@font-face {
  font-family: "DB Heavent";
  src: url("../font/DBHeavent.eot");
  src: url("../font/DBHeavent.eot?#iefix") format("embedded-opentype"),
    url("../font/DBHeavent.woff2") format("woff2"),
    url("../font/DBHeavent.woff") format("woff"),
    url("../font/DBHeavent.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'DBHeaventBl';
  src: url('../font/DBHeaventBlkv3_2_1.eot');
  src: url('../font/DBHeaventBlkv3_2_1.eot') format('embedded-opentype'),
       url('../font/DBHeaventBlkv3_2_1.woff2') format('woff2'),
       url('../font/DBHeaventBlkv3_2_1.woff') format('woff'),
       url('../font/DBHeaventBlkv3_2_1.ttf') format('truetype'),
       url('../font/DBHeaventBlkv3_2_1.svg#DBHeaventBlkv3_2_1') format('svg');
}
